// import navigation from "../../../json/navigation.json";

import { markFailure, markLoading, markSuccess } from '../../util';

export const LOAD_DOMAIN_REQUEST = 'campaign/LOAD_DOMAIN_REQUEST';
export const LOAD_DOMAIN_SUCCESS = 'campaign/LOAD_DOMAIN_SUCCESS';
export const LOAD_DOMAIN_FAILURE = 'campaign/LOAD_DOMAIN_FAILURE';

export const SET_THEME = 'campaign/SET_THEME';
export const LOAD_THEME_REQUEST = 'campaign/LOAD_THEME_REQUEST';
export const LOAD_THEME_SUCCESS = 'campaign/LOAD_THEME_SUCCESS';
export const LOAD_THEME_FAILURE = 'campaign/LOAD_THEME_FAILURE';

export const SAVE_THEME_REQUEST = 'campaign/SAVE_THEME_REQUEST';
export const SAVE_THEME_SUCCESS = 'campaign/SAVE_THEME_SUCCESS';
export const SAVE_THEME_FAILURE = 'campaign/SAVE_THEME_FAILURE';

const INTERNAL_DOMAINS = [
  'dev.infloso.com',
  'pre.infloso.com',
  'uat.infloso.in',
  'www.infloso.in',
  'www.infloso.com',
  'preprod.infloso.in',
  'dev-cdn.infloso.in',
  'cdn.infloso.in',
  'cdnc.infloso.com',
];

const defaultTheme = {
  name: 'Infloso',
  theme: '',
  domain:
    typeof location !== 'undefined' ? location.hostname : 'www.infloso.in',
  subdomain:
    typeof location !== 'undefined' ? location.hostname : 'www.infloso.in',
  icon: '/favicon-rounded-200x200.png',
  logo: '/favicon-transparent-l-200x200.png',
  left_nav_color: '#0074DB',
  social_login: true,
  is_agency: true,
};

const initialState = {
  name: 'Loading...',
  description:
    'No subscription or onboarding fee. Sign up for free & get started.',
  // logo: '/64x64.png',
  url: 'https://www.infloso.com',
  left_nav_color: '#0074DA',
  left_nav_text_color: '#0074DA',
  background_color: 'gray',
  // social_login: false,
  // is_agency: false,
  is_agency: true,
  layout: 'layout-3',
  adminLayout: 'layout-1',
  direction: 'ltr',
  collapsed: typeof window !== 'undefined' && window.innerWidth < 1024,
  toggleRightSidebar: false,
  social: {
    facebook: {
      appId: '479885530196931',
      scope: 'email,public_profile',
      connect_scope: 'email,public_profile,user_posts',
    },
    google: {
      clientId:
        '979433211340-995jsdoumal44o72h7rgs5o6ci9uoh3l.apps.googleusercontent.com',
      apiKey: 'AIzaSyDAVGQGamo1YRARqTV7ZBUkvwKhwPkJMzQ',
      // scope: ['https://www.googleapis.com/auth/userinfo.email','https://www.googleapis.com/auth/userinfo.profile']
    },
    youtube: {
      clientId:
        '979433211340-995jsdoumal44o72h7rgs5o6ci9uoh3l.apps.googleusercontent.com',
      apiKey: 'AIzaSyDAVGQGamo1YRARqTV7ZBUkvwKhwPkJMzQ',
      scope: 'https://www.googleapis.com/auth/youtube.readonly',
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest',
      ],
    },
    instagram: {
      appId: '731493081120790',
      client_id: 3775685332493619,
      redirect_uri: 'https://www.infloso.in/auth',
      connect_basic_scope: 'user_profile,user_media',
      connect_scope:
        'instagram_basic,pages_show_list,instagram_manage_insights', //,instagram_graph_user_profile,instagram_graph_user_media'
    },
  },
  // navigation: navigation,
  colors: [
    'gray',
    'red',
    'orange',
    'yellow',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'pink',
  ],
  palettes: {
    background: 'bg-gray-100',
    logo: 'white',
    leftSidebar: 'bg-brand-light',
    rightSidebar: 'white',
    navbar: 'white',
    topNavigation: 'white',
  },
  leftSidebar: {
    showButtonText: true,
    showSectionTitle: false,
    showLogo: true,
    showCard: true,
    showAccountLinks: false,
    showProjects: false,
    showTags: false,
    card: 4,
  },
  loading: [],
  errors: {},
  success: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case LOGOUT_SUCCESS: {
    //   //Preserving theme on logout
    //   const {
    //     name,
    //     theme,
    //     domain,
    //     subdomain,
    //     logo,
    //     left_nav_color,
    //     social_login,
    //     is_agency,
    //   } = { ...defaultTheme, ...state };
    //
    //   const loadedTheme = {
    //     name,
    //     theme,
    //     domain,
    //     subdomain,
    //     logo,
    //     left_nav_color,
    //     social_login,
    //     is_agency,
    //   };
    //
    //   console.log('th', state.left_nav_color, loadedTheme.left_nav_color);
    //
    //   return { ...initialState, ...loadedTheme };
    // }
    case 'SET_DEMO':
      return {
        ...state,
        layout: action.layout,
        collapsed: action.collapsed,
        direction: action.direction,
        leftSidebar: {
          ...action.leftSidebar,
        },
        palettes: {
          ...action.palettes,
        },
      };
    case 'SET_PALETTE':
      return {
        ...state,
        palettes: {
          ...state.palettes,
          [`${action.key}`]: action.value,
        },
      };
    case 'SET_LEFT_SIDEBAR_CONFIG':
      return {
        ...state,
        leftSidebar: {
          ...state.leftSidebar,
          [`${action.key}`]: action.value,
        },
      };
    case 'SET_LAYOUT':
      if (action.layout === 'layout-3' || action.layout === 'layout-4') {
        return {
          ...state,
          layout: action.layout,
          collapsed: true,
        };
      }
      return {
        ...state,
        layout: action.layout,
        collapsed: false,
      };
      return {
        ...state,
        [`${key}`]: value,
      };
    case 'SET_CONFIG': {
      let { key, value } = { ...action.config };
      return {
        ...state,
        [`${key}`]: value,
      };
    }
    case LOAD_DOMAIN_SUCCESS: {
      let resp = action.result;
      return {
        ...state,
        ...resp,
      };
    }
    case SET_THEME: {
      let resp =
        typeof location !== 'undefined' &&
        INTERNAL_DOMAINS.indexOf(location.hostname) > -1
          ? defaultTheme
          : {
              name: action.result.name,
              theme: action.result.theme,
              domain: action.result.domain,
              subdomain: action.result.subdomain,
              logo: action.result.logo,
              left_nav_color: action.result.left_nav_color,
            };

      return {
        ...state,
        ...resp,
      };
    }
    case LOAD_THEME_SUCCESS: {
      let resp =
        typeof location !== 'undefined' &&
        INTERNAL_DOMAINS.indexOf(action.payload.domain || location.hostname) >
          -1
          ? defaultTheme
          : {
              name: action.result.name,
              theme: action.result.theme,
              domain: action.result.domain,
              subdomain: action.result.subdomain,
              logo: action.result.logo,
              icon: action.result.logo,
              left_nav_color: action.result.left_nav_color,
              is_agency: false,
            };

      if (
        INTERNAL_DOMAINS.indexOf(action.payload.domain || location.hostname) ===
          -1 &&
        action.result.constants
      ) {
        resp.name = action.result.constants.product_name || state.name;
        resp.left_nav_color =
          action.result.constants.theme_color || state.left_nav_color;

        if (action.result.agency_file && action.result.agency_file.length > 0) {
          resp.logo = action.result.agency_file[0].file_location;
        }
        resp.is_agency = true;
      }
      return {
        ...state,
        ...resp,
      };
    }
    case SAVE_THEME_REQUEST: {
      const lmark = markLoading(action, state);
      return { ...state, ...lmark };
    }
    case SAVE_THEME_SUCCESS: {
      const theme = {};

      if (action.payload.key) {
        switch (action.payload.key) {
          case 'theme_color':
            theme.left_nav_color = action.payload.value;
            break;
          case 'product_name':
            theme.name = action.payload.value;
        }
      }
      const smark = markSuccess(action, state);
      return { ...state, ...smark, ...theme };
    }
    case SAVE_THEME_FAILURE: {
      const fmark = markFailure(action, state);
      return { ...state, ...fmark };
    }
    default:
      return { ...state };
  }
}

export function showDemo() {
  return {
    type: 'SET_DEMO',
    layout: 'layout-1',
    direction: 'ltr',
    collapsed: false,
    palettes: {
      background: 'white',
      logo: 'bg-indigo-700',
      leftSidebar: 'white',
      rightSidebar: 'white',
      navbar: 'white',
      topNavigation: 'white',
    },
    leftSidebar: {
      showButtonText: true,
      showSectionTitle: true,
      showLogo: true,
      showCard: true,
      showAccountLinks: false,
      card: 1,
    },
  };
}

// export function loadDomain() {
//   return {
//     payload: {},
//     mark: { 'load-domain': 1 },
//     types: [LOAD_DOMAIN_REQUEST, LOAD_DOMAIN_SUCCESS, LOAD_DOMAIN_FAILURE],
//     promise: (client) => client(`/api/domain`, {}, true),
//   };
// }

export function loadTheme(userDomain) {
  const domain = userDomain || location.hostname;

  if (INTERNAL_DOMAINS.indexOf(domain) > -1) {
    return {
      payload: { domain },
      type: SET_THEME,
    };
  } else {
    return {
      payload: { domain },
      mark: { 'load-theme': 1 },
      types: [LOAD_THEME_REQUEST, LOAD_THEME_SUCCESS, LOAD_THEME_FAILURE],
      promise: (client) =>
        client(`/inflosotheme/upload-agency-theme?domain=${domain}`, {}),
    };
  }
}

export function saveTheme(data) {
  return (dispatch, getState) => {
    const { name, theme, domain, subdomain } = getState().ui;
    const savedPayload = {
      name,
      theme,
      domain,
      subdomain,
    };

    const cData = { ...data };
    const mData = { ...data };

    delete cData.name;
    delete cData.theme;
    delete cData.domain;
    delete cData.subdomain;

    const kv = { key: '', value: '' };

    Object.keys(cData).map((k, i) => {
      kv.key = k;
      kv.value = cData[k];
      delete mData[k];
    });

    if (data.domain) {
      mData.name = mData.name || mData.domain.replaceAll('.', '_');
      mData.theme = mData.theme || mData.domain.replaceAll('.', '_');
    }

    const payload = {
      ...savedPayload,
      ...mData,
      ...kv,
    };

    return dispatch({
      payload: payload,
      mark: { ...kv },
      types: [SAVE_THEME_REQUEST, SAVE_THEME_SUCCESS, SAVE_THEME_FAILURE],
      promise: (client) =>
        client(
          `/inflosotheme/upload-agency-theme`,
          { method: 'POST', body: JSON.stringify(payload) },
          true
        ),
    });
  };
}
