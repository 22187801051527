import React from 'react';
import { loadScript, ls } from '../services/util';

export const googleInit = (google) => {
  return new Promise((resolve, reject) => {
    let gapi = window.gapi;

    function start() {
      const config = {
        // 'apiKey': google.apiKey,
        client_id: google.clientId,
        access_type: 'offline',
        // scope:google.scope
      };

      if (google.scope) {
        config.scope = google.scope;
      }

      gapi.auth2
        .init(config)
        .then(function () {
          const GoogleAuth = gapi.auth2.getAuthInstance();
          resolve(GoogleAuth);
        })
        .catch(function (err) {
          reject(err);
        });
    }

    window.googleAsyncInit = () => {
      gapi.load('auth2', start);
    };

    if (!gapi) {
      loadScript(
        'https://apis.google.com/js/platform.js?onload=googleAsyncInit',
        () => {
          gapi = window.gapi;
        }
      );
    } else {
      const GoogleAuth = gapi.auth2.getAuthInstance();
      resolve(GoogleAuth);
    }
  });
};

export const fbInit = (facebook) => {
  return new Promise((resolve) => {
    if (window.FB) {
      FB.init({
        appId: facebook.appId,
        version: 'v21.0',
      });

      resolve(FB);
    } else {
      window.fbAsyncInit = () => {
        FB.init({
          appId: facebook.appId,
          version: 'v21.0',
        });

        resolve(FB);
      };
    }

    loadScript('https://connect.facebook.net/en_US/sdk.js', () => {});
  });
};

export const fbLogin = (facebook, scope) => {
  return new Promise(async (resolve) => {
    let FB = window.FB;
    // if (!FB) {
    FB = await fbInit(facebook);
    // }
    FB.login(
      function (response) {
        resolve(response);
        //     if (response.authResponse) {
        //       FB.api('/me?fields=id,name,first_name,last_name,picture,email', async function (api_response) {
        //         api_response.accessToken = response.authResponse.accessToken;
        //         resolve(api_response);
        //       });
        //     } else {
        //       // dispatch(actions.user.showError('Could not complete Login'))
        //       // if (props.onFailure) {
        //       //   props.onFailure(response);
        //       // }
        //     }
      },
      {
        scope: scope || facebook.scope,
        auth_type: 'rerequest',
        return_scopes: true,
      }
    );
  });
};

export const googleAdditionalScope = (google) => {
  return new Promise(async (resolve, reject) => {
    if (window.gapi) {
      //Google Already initiased
      if (gapi.auth2.getAuthInstance().currentUser.get()) {
        //Issigned in
        const option = new gapi.auth2.SigninOptionsBuilder();
        option.setScope(google.scope);
        const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
        googleUser.grant(option).then(
          function (success) {
            if (success) {
              const resp = success.getAuthResponse(true);
              if (resp) {
                resolve({ accessToken: resp.access_token });
              } else {
                resolve({ accessToken: null });
              }
            } else {
              resolve({ accessToken: null });
            }
          },
          function (fail) {
            //alert(JSON.stringify({message: "fail", value: fail}));
            //reject(fail);
          }
        );
      } else {
        //User not found
        try {
          resolve(await googleLogin(google));
        } catch (e) {
          reject(e);
        }
      }
    } else {
      //Google not initialised
      try {
        resolve(await googleLogin(google));
      } catch (e) {
        reject(e);
      }
    }
  });
};

export const googleLogin = (google) => {
  return new Promise(async (resolve, reject) => {
    const updateSigninStatus = (s) => {
      try {
        const response = s.getAuthResponse(true);
        const accessToken = response.access_token;
        resolve({ accessToken });
      } catch (e) {
        reject({ error: e });
      }
    };

    let GoogleAuth;
    try {
      GoogleAuth = await googleInit(google);
    } catch (e) {
      reject(e);
    }
    if (GoogleAuth) {
      if (GoogleAuth.currentUser) {
        GoogleAuth.currentUser.listen(updateSigninStatus);
      } else {
        GoogleAuth.isSignedIn.listen(updateSigninStatus);
      }

      try {
        return await GoogleAuth.signIn();
      } catch (e) {
        resolve(null);
      }
    } else {
    }
  });
};

export const instagramConnect = (instagram) => {
  return fbLogin(instagram, instagram.connect_scope);
};

export const fbConnect = (facebook) => {
  return fbLogin(facebook, facebook.connect_scope);
};

// export const youtubeConnect = (youtube) => {
//   return new Promise((resolve => {
//     const updateSigninStatus = (s) => {
//       const accessToken = s.getAuthResponse().access_token;
//       resolve({accessToken});
//     }
//
//     function start() {
//       gapi.client.init({
//         'apiKey': youtube.apiKey,
//         'clientId': youtube.clientId,
//         'scope': youtube.scope,
//         'discoveryDocs': youtube.discoveryDocs
//       }).then(function () {
//         const GoogleAuth = gapi.auth2.getAuthInstance();
//         // Listen for sign-in state changes.
//
//         if (GoogleAuth.currentUser) {
//           GoogleAuth.currentUser.listen(updateSigninStatus);
//         } else {
//           GoogleAuth.isSignedIn.listen(updateSigninStatus);
//         }
//
//         //gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
//         // const user = GoogleAuth.currentUser && GoogleAuth.currentUser.get();
//         // const accessToken =  user.getAuthResponse().access_token;
//         // if(accessToken){
//         //   resolve({accessToken});
//         // }else {
//         return GoogleAuth.signIn();
//         // }
//       });
//     }
//
//     gapi.load('client:auth2', start);
//
//   }))
// }

export const youtubeConnect = (youtube) => {
  return googleLogin(youtube);
};

export const instagramBasicConnect = (instagram) => {
  return new Promise((resolve) => {
    const win = window.open(
      `https://www.instagram.com/oauth/authorize?client_id=${instagram.client_id}&redirect_uri=${instagram.redirect_uri}&scope=${instagram.connect_basic_scope}&response_type=code&state=1`
    );
    // const win = window.open(`https://dev.infloso.com/auth?code=abcd`);
    //just change

    const intrv = setInterval(() => {
      const code = ls.getItem('insta_code');
      if (code) {
        clearInterval(intrv);
        ls.removeItem('insta_code');
        // win.close();
        // resolve({code});
      }
    }, 1000);
  });
};
